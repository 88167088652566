import React from "react";
import "./Banner.css"




function Banner() {
    return <div >
        
    </div>
}

export default Banner;